<template>
  <layout>
    <div>
      <div class="flex items-center">
        <img src="@/assets/images/mv-logo.svg" alt="My Vends 2.0" class="w-12 h-12 mr-2">
        <h2 class="text-4xl tracking-widest">MyVends</h2>
      </div>
      <p class="mt-2 text-lg max-w text-gray-500">
        Register new account.
      </p>
    </div>

    <div class="mt-8">
      <div class="mt-6">
        <form class="space-y-6" @submit.prevent="register">
          <error v-if="errorMsg">{{ errorMsg }}</error>
          <text-input v-model="email" type="email" name="email" label="Email address" placeholder="Email address" :is-loading="isLoading" />
          <text-input v-model="password" autocomplete="new-password" type="password" name="password" placeholder="Password" label="Password" :is-loading="isLoading" />
          <text-input v-model="passwordConfirmation" type="password" name="password-confirmation" label="Confirm Password" placeholder="Confirm Password" :is-loading="isLoading" />
          <div class="mt-2">
            <label class="flex items-center">
              <input type="checkbox" class="rounded text-big-stone border-big-stone ffocus:outline-none focus:ring-2 focus:ring-sail focus:shadow-outline-sail active:bg-blumine" v-model="agreed">
              <span class="ml-2 text-gray-700">I agree with <a href="https://dev.diad9uut72y3m.amplifyapp.com/terms/index.html" class="underline" target="_blank">terms of use</a></span>
            </label>
          </div>
          <div>
            <button
              type="submit"
              :class="{ 'cursor-not-allowed': isLoading }"
              :disabled="isLoading"
              class="w-full flex justify-center items-center py-2 px-4 border border-transparent font-semibold text-base rounded-md text-white bg-big-stone hover:bg-blumine focus:outline-none focus:ring-2 focus:ring-sail focus:shadow-outline-sail active:bg-blumine transition duration-150 ease-in-out">
              <loading v-show="isLoading" class="w-5 h-5 mr-3" /> Register
            </button>
          </div>
        </form>
        <div class="text-sm text-center mt-6">
            <router-link :to="{ name: 'Login' }" class="text-blumine underline hover:text-big-stone focus:outline-none focus:shadow-outline transition ease-in-out duration-150">
              I already have account
            </router-link>
          </div>
      </div>
    </div>
  </layout>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { Auth } from '@/services/auth.js'
import Layout from '@/layouts/Auth.vue'
import Loading from '@/components/icons/Loading.vue'
import TextInput from '@/components/form/Text.vue'
import Error from '@/components/form/Error.vue'

export default {
  components: {
    Layout,
    Loading,
    TextInput,
    Error,
  },

  setup() {
    const router = useRouter()
    const email = ref('')
    const password = ref('')
    const passwordConfirmation = ref('')
    const agreed = ref(false)
    let errorMsg = ref('')
    let isLoading = ref(false)


    const arePasswordsEqual = () => {
      return password.value === passwordConfirmation.value
    }

    const onSuccess = () => {
      localStorage.setItem('email', email.value)
      router.push({ name: 'RegisterConfirm' })
    }

    const register = async () => {
      if (! arePasswordsEqual()) {
        errorMsg.value = 'The password confirmation does not match'

        return
      }

      if (! agreed.value) {
        errorMsg.value = 'You must agree with terms of use'

        return
      }

      isLoading.value = true

      try {
        await Auth.register(email.value, password.value)
        onSuccess()
      } catch (error) {
        errorMsg.value = error.message
      }
      isLoading.value = false
    }

    return {
      email,
      password,
      passwordConfirmation,
      agreed,
      errorMsg,
      isLoading,
      register
    }
  }
}
</script>
